export const sortTextCaseInsensitive = (a, b, descending = false) =>
	a.localeCompare(b, undefined, { sensitivity: "base", numeric: true }) * (descending ? -1 : 1);

export const combineTitleAndCode = (a, b) => {
	const combineA = (a.code + a.title).toLowerCase();
	const combineB = (b.code + b.title).toLowerCase();

	return sortTextCaseInsensitive(combineA, combineB);
};

export const sortTextFieldCaseInsensitive = (a, b, field, descending = false) => sortTextCaseInsensitive(a[field], b[field], descending);

export const sortBoolean = (a, b, descending = false) => (a < b ? -1 : a > b ? 1 : 0) * (descending ? -1 : 1);

export const sortBooleanField = (a, b, field, descending = false) => sortBoolean(a[field], b[field], descending);

export const sortNumeric = (a, b, descending = false) => (a < b ? -1 : a > b ? 1 : 0) * (descending ? -1 : 1);

export const sortNumericField = (a, b, field, descending = false) => sortNumeric(a[field], b[field], descending);

export const sortDefault = (a, b) => {
	const folderSort = sortBooleanField(a, b, "folder", true);
	return folderSort !== 0 ? folderSort : combineTitleAndCode(a, b);
};

export const getSortMethod = (field, descending) => {
	let method = sortDefault;
	switch (field) {
		case "title":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "title", descending);
			break;

		case "dateModified":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "dateModified", descending);
			break;

		case "modifiedBy":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "modifiedBy", descending);
			break;

		case "dateDeleted":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "dateDeleted", descending);
			break;

		case "deletedBy":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "deletedBy", descending);
			break;
	}

	return method;
};

export const sortPolicyDocuments = (a, b) => {
	const sortKeyA = a.code || a.title;
	const sortKeyB = b.code || b.title;
	//sorting by code or title with numeric handling
	const sortOrder = sortKeyA.localeCompare(sortKeyB, undefined, { numeric: true });
	if (sortOrder !== 0) return sortOrder;
	//sorting by title if code is same
	return a.title.localeCompare(b.title);
};
