import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import trimChars from "lodash/fp/trimChars";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

import { API_HOST } from "config/env";
import { FOLDER_TYPE_RECYCLE_BIN, FOLDER_TYPE_DRAFT_POLICY } from "utils/enums/DocumentFolderTypes";
import { sortDefault, getSortMethod, sortPolicyDocuments } from "utils/sort";
import Breadcrumbs from "atlas/components/Breadcrumbs/Breadcrumbs";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import { resetPageConfigs, updatePageConfigs, updateLeftNav } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { endsWithAllowedExtensionForPolicy, getAllowedAcceptForPolicy } from "utils/allowedExtensions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { getScrollParent } from "utils/scrollToElement";
import PolicyTopBar from "../Policy/components/PolicyTopBar";
import AddFolderDialog from "../Policy/components/AddFolderDialog";
import PublishSectionNameDialog from "./components/PublishSectionNameDialog";
import getPathType from "../Documents/utils/pathType";
import PolicyList from "./PolicyList";
import {
	getPolicies,
	getPermissions,
	getPath,
	addNewPolicy,
	getMeetingGroupDetails,
	addPolicyToAgendaItem,
} from "redux/policyGovernance/actions";
import PolicyMoveCopyDialog from "./components/PolicyMoveCopyDialog";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import { useUpdateObject } from "utils/updateObject";
import FilterPanel from "components/Panels/FilterPanel";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import RadioButton from "atlas/components/FormControls/RadioButton";
import PolicyRestoreDialog from "./components/PolicyRestoreDialog";
import PolicyPermanentlyDeleteDialog from "./components/PolicyPermanentlyDeleteDialog";
import UploadDialog from "./components/UploadDialog";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
import { getPathForEditPage } from "views/Policy/utils/getPathForEditPage";
import { UserContext } from "contexts/User/UserContext";
import AddToAgendaDialog from "components/Dialogs/AddToAgendaDialog";
import PolicyDeleteDialog from "./components/PolicyDeleteDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import AddPolicyDialog from "../Policy/components/AddPolicyDialog";

const useStyles = makeStyles({
	breadcrumbs: {
		height: "40px",
		padding: "0 8px",
	},
	fileInput: {
		display: "none",
	},
	overlayLoader: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100vw",
		height: "100vh",
		background: "rgb(111,111,111,0.5)",
		zIndex: 9,
	},
	loader: {
		zIndex: 10,
	},
});

const telemetryPage = "Document List";

const ROOT_ID = "root";

const PolicyModule = (props) => {
	const { type = FOLDER_TYPE_DRAFT_POLICY, showSignIn } = props;
	const { params: { id: publicId } = {} } = useMatch({ path: "/policy/policybook/:id", end: true }) || {};
	const { params: { id: recycleBinId } = {} } = useMatch({ path: "/policy/recyclebin/:id", end: true }) || {};
	const id = publicId || recycleBinId;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [policies, setPolicies] = useState(null);
	const [expandedPolicies, setExpandedPolicies] = useState([]);
	const [filteredPolicies, setFilteredPolicies] = useState(null);
	const [filterOn, setFilterOn] = useState(false);
	const [path, setPath] = useState(null);
	const [breadPath, setBreadPath] = useState(null);
	const [permissions, setPermissions] = useState(null);
	const [order, setOrder] = useState({});
	const [dialogs, setDialogs] = useState({});
	const [focusGuid, setFocusGuid] = useState(null);
	const input = useRef(null);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [filter, setFilter] = useState({
		type: 0,
	});
	const [panels, setPanels] = useState({});
	const [navigateNewPolicy, setNavigateNewPolicy] = useState(false);
	const [landPage, setLandPage] = useState(true);
	const [enableRecycleTopBar, setEnableRecycleTopBar] = useState(false);
	const isRecycleBin = type === FOLDER_TYPE_RECYCLE_BIN;
	const { id: userId } = useContext(UserContext).user;
	const { policyEnabled } = useContext(SettingsContext);
	const [meetingGroups, setMeetingGroups] = useState([]);
	const [showAddToMeetingDialog, setShowAddToMeetingDialog] = useState(false);
	const [policyGuid, setPolicyGuid] = useState();
	const [childFolderGuids, setChildFolderGuids] = useState([]);
	const [isNewPolicyModalOpen, setPolicyModalStatus] = useState(false);
	const [policyDetails, setPolicyDetails] = useState({
		title: "",
		code: "",
	});
	const toggleNewPolicyModal = () => setPolicyModalStatus(!isNewPolicyModalOpen);

	let today = new Date();
	today = today.toISOString();
	const backToParent = useCallback(() => {
		if (breadPath && breadPath.length > 1 && breadPath[breadPath.length - 2].url) {
			navigate(breadPath[breadPath.length - 2].url);
		}

		return true;
	}, [breadPath]);

	const setTitle = useCallback(
		(title) => {
			dispatch(
				updatePageConfigs({
					title,
					telemetryPage,
					back: path && path.length > 1 ? { action: backToParent } : undefined,
				}),
			);
		},
		[path],
	);
	const updateFilter = useUpdateObject(setFilter);

	const filterPanelChange = (newFilter) => {
		setFilter(newFilter);
	};
	const closeFilter = () => setPanels({});

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const loadDocuments = (parentId, root, parent) => {
		policyEnabled
			? dispatch(getPolicies({ type, parentId }))
					.then((res) => {
						// Set selections
						if (type === FOLDER_TYPE_RECYCLE_BIN && parent) {
							res.forEach((document) => {
								document.selected = parent.selected;
							});
						}

						setPolicies((prev) => {
							// Check if the page has changed. If it has make no changes to the documents
							if (prev.folder.id !== id || prev.folder.type !== type) {
								return prev;
							}

							const newDocuments = root ? { folder: prev.folder } : { ...prev };
							newDocuments[root || !parentId ? "root" : parentId] = res.sort(sortPolicyDocuments);
							return newDocuments;
						});
					})
					.catch((err) => {
						showSignIn(err, () => {
							loadDocuments(parentId, root, parent);
						});
					})
			: navigate("/");
	};

	const loadPath = async () => {
		const pathType = getPathType(type);
		const rootName = t(`title.${type === FOLDER_TYPE_DRAFT_POLICY ? "draftPolicies" : "recycleBin"}`);
		if (!id) {
			// Root folder
			setPath([
				{
					name: rootName,
				},
			]);
			setBreadPath([
				{
					name: rootName,
				},
			]);
		} else {
			await dispatch(getPath(id))
				.then((res) => {
					setPath(res);
					// const breadArray = [...res];
					// breadArray && breadArray.shift();
					setBreadPath(
						res.map((path, index) => ({
							name: path.code + " " + path.title,
							url: index < res.length - 1 ? `/policy/${pathType}/${path.guid}` : undefined,
						})),
					);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadPath();
					});
				});
		}
	};

	const loadPermissions = () => {
		dispatch(getPermissions(type, id))
			.then((res) => {
				setPermissions(res);
			})
			.catch((err) => {
				addDocument;
				showSignIn(err, () => {
					loadPermissions();
				});
			});
	};

	const addDocument = useCallback(
		(document, parentGuid) => {
			setPolicies((prev) => {
				const parent = document.parentGuid || parentGuid;
				const folderGuid = !parent || parent === id ? ROOT_ID : typeof prev[parent] !== "undefined" ? parent : null;

				if (folderGuid) {
					// Add new document or folder to list without needing full reload
					prev[folderGuid].push(document);
					prev[folderGuid].sort(getSortMethod(order.field, order.descending));
				}

				return folderGuid ? { ...prev } : prev; // Only update state if the list changed
			});
		},
		[id, order],
	);

	const removeDocuments = (documents) => {
		setPolicies((prev) => {
			Object.keys(prev).forEach((key) => {
				if (Array.isArray(prev[key])) {
					documents.forEach((document) => {
						const index = prev[key].indexOf(document);
						if (index >= 0) {
							prev[key].splice(index, 1);
						}
					});
				}
			});

			return { ...prev };
		});
	};

	const getSelected = useCallback(() => {
		const selected = [];

		Object.keys(policies).forEach((key) => {
			if (Array.isArray(policies[key])) {
				policies[key]
					.filter((document) => document.selected)
					.forEach((document) => {
						selected.push(document);
					});
			}
		});

		return selected;
	}, [id, policies]);

	const uploadClick = () => {
		input.current.click();
	};

	const uploadInputChange = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		const invalidFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithAllowedExtensionForPolicy(file.name)) {
				filteredFiles.push(file);
			} else {
				invalidFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			setDialogs({
				upload: {
					files: filteredFiles
						.sort((first, second) =>
							// eslint-disable-next-line no-nested-ternary
							first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
						)
						.map((file) => ({
							file,
							name: trimChars("\\/", file.webkitRelativePath || file.name),
							path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
						})),
					invalidFiles,
				},
			});
		} else if (invalidFiles.length > 0) {
			setDialogs({
				failedUploads: invalidFiles,
			});
		}
	};

	const afterUploadFile = (document) => {
		addDocument(document);
		navigate(getPathForEditPage(document.guid, null));
	};

	const addFolderClick = () => {
		setDialogs({ addFolder: true });
	};

	const newPolicyClick = () => {
		setNavigateNewPolicy(true);
		dispatch(addNewPolicy(id, policyDetails))
			.then((res) => {
				if (res.guid !== null) {
					setNavigateNewPolicy(false);
					navigate(`/policydoc/edit/${res.guid}`);
				} else {
					throw res;
				}
			})
			.catch((err) => {
				if (err?.guid === null) {
					displayErrorNotification(t("policy.errorMsg.createPolicy"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
				setNavigateNewPolicy(false);
			});
	};

	const onClearSearchClick = () => {
		navigate(`/policy/policybook/${id}`);
	};

	const afterAddFolder = (folder) => {
		addDocument(folder);

		setFocusGuid(folder.guid);
	};

	const permanentlyDeleteClick = () => {
		setDialogs({ permanentlyDelete: true });
	};

	const restoreClick = () => {
		setDialogs({ restore: true });
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const closeUpload = (failedUploads = []) => {
		if (failedUploads.length === 0) {
			closeDialogs();
		} else {
			setDialogs({
				failedUploads,
			});
		}
	};

	const onAddToAgendaClick = (policy) => {
		setPolicyGuid(policy.guid ? policy.guid : policy.publishedGuid);

		if (policy.isAdmin && policy.canUpdate) {
			dispatch(getMeetingGroupDetails(userId)).then((res) => {
				setMeetingGroups(res);
			});
		} else if (policy.canUpdate || policy.isStaff) {
			dispatch(addPolicyToAgendaItem(policy.guid ? policy.guid : policy.publisheduid)).then((res) => {
				navigate(`/agendaitems/edit/${res.itemGuid}`);
			});
		} else {
			displayErrorNotification("Access Denied", dispatch);
		}
	};

	const setExpandedQuery = useCallback(() => {
		// Get a list of the expanded policies
		const expanded = [];
		Object.keys(policies).forEach((key) => {
			if (Array.isArray(policies[key])) {
				expanded.push(...policies[key].filter((policy) => policy.expanded).map((policy) => policy.guid));
			}
		});

		// Set the expanded policies in the query string
		try {
			let currentWindow = window;
			while (currentWindow) {
				const queryString = new URLSearchParams(currentWindow.location.search);
				if (expanded.length > 0) {
					queryString.set("expanded", expanded.join(","));
				} else {
					queryString.delete("expanded");
				}
				const queryStringString = queryString.toString();
				currentWindow.history.pushState(
					{},
					"",
					`${currentWindow.location.pathname}${queryStringString.length > 0 ? `?${queryStringString}` : ""}`,
				);

				if (currentWindow !== currentWindow.parent) {
					currentWindow = currentWindow.parent;
				} else {
					break;
				}
			}
		} catch {
			// Ignored
		}
	}, [policies]);

	const handlePolicyEvent = ({ eventName, document, keyboard }) => {
		switch (eventName) {
			case "expand":
				document.expanded = !document.expanded;
				document.focusChild = document.expanded;
				document.focusKeyboard = keyboard;

				// Trigger re-render
				setPolicies((prev) => {
					// Load children if needed
					if (document.expanded && !prev[document.guid]) {
						loadDocuments(document.guid, false, document);
					}

					return { ...prev };
				});

				setExpandedQuery();
				break;

			case "rename":
				setDialogs({ rename: document });
				break;

			case "publish-section-name":
				setDialogs({ publishSectionName: document });
				break;

			case "delete":
				setDialogs({ delete: document });
				break;

			case "permanently-delete":
				setDialogs({ permanentlyDelete: [document] });
				break;

			case "restore":
				setDialogs({ restore: [document] });
				break;

			case "editdraft":
				navigate(`/policydoc/edit/${document.draftGuid ? document.draftGuid : document.guid}`);
				break;

			case "copyMove":
				setDialogs({ copyMove: document });
				break;

			case "addtomeeting":
				onAddToAgendaClick(document);
				break;

			case "deleteSection":
				setDialogs({ delete: document });
				break;
		}
	};

	const handleSort = (field, orderType) => {
		setOrder({ field: field, descending: orderType !== "asc" });
	};

	const cascadeSelections = (documents, parentSelections) => {
		if (parentSelections.length > 0) {
			let updated = [];
			parentSelections.forEach((parent) => {
				if (Array.isArray(documents[parent.guid])) {
					documents[parent.guid].forEach((document) => {
						document.selected = parent.selected;

						if (document.folder) {
							updated.push(document);
						}
					});
				}
			});

			cascadeSelections(documents, updated);
		}
	};

	const checkChildSelections = (documents, childDocuments = []) => {
		const selection = {
			selected: false,
			notSelected: false,
			hasChildren: childDocuments.length > 0,
		};
		childDocuments.forEach((document) => {
			// Check if children are selected
			if (document.folder) {
				const childSelection = checkChildSelections(documents, documents[document.guid]);
				if (childSelection.hasChildren) {
					document.selected = childSelection.selected && !childSelection.notSelected;
					document.childrenSelected = childSelection.selected;
					setChildFolderGuids((prev) => [...prev, document.guid]);
				}
			}

			// Set if there are selected or not selected documents on the current level
			selection.selected = selection.selected || document.selected;
			selection.notSelected = selection.notSelected || !document.selected;
		});

		return selection;
	};

	const handleCheck = (guids = [], checked = false) => {
		setPolicies((prev) => {
			let updatedFolders = [];
			let updatedKeys = [];
			Object.keys(prev).forEach((key) => {
				if (Array.isArray(prev[key])) {
					const updatedDocuments = prev[key].filter((document) => guids.includes(document.guid));
					updatedDocuments.forEach((document) => {
						document.selected = checked;

						if (document.folder) {
							updatedFolders.push(document);
						}
					});

					if (key !== ROOT_ID) {
						updatedKeys.push(key);
					}
				}
			});

			cascadeSelections(prev, updatedFolders);
			checkChildSelections(prev, prev[ROOT_ID]);

			return { ...prev };
		});
	};

	const handleSearch = (keywords) => {
		telemetryAddEvent(`${telemetryPage} - Library - Search initiated`, { area: "documents" });

		if (keywords && keywords.length > 0) {
			navigate(
				`/policy/search/${getPathType(type)}${type === FOLDER_TYPE_DRAFT_POLICY ? `/${id}` : ""}?keywords=${encodeURIComponent(keywords)}`,
			);
		}
	};

	const afterRename = () => {
		setPolicies((prev) => ({ ...prev }));
	};

	const afterCopyMove = (document) => {
		displaySuccessNotification(t("policy.successMsg.duplicatePolicy"), dispatch);
		navigate(getPathForEditPage(document.guid, document.publishedGuid));
	};

	const afterDelete = (document, parentGuid) => {
		telemetryAddEvent(`${telemetryPage} - Library - Deleted document`, { area: "documents" });

		setPolicies((prev) => {
			prev.root.forEach((record, index) => {
				if (record.guid === parentGuid) {
					prev.root.splice(index, 1);
				}
			});
			return { ...prev };
		});
	};

	const undoDelete = (document, parentGuid) => {
		request
			.post(`${API_HOST}/api/document/${document.guid}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				displaySuccessNotification(t("deleteDocumentDialog.undo.successful"), dispatch);
				addDocument(document, parentGuid);
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(document, parentGuid);
				});
			});
	};

	const afterRestore = (documents) => {
		telemetryAddEvent(`${telemetryPage} - Library - Restored document`, { area: "documents" });

		removeDocuments(documents);

		if (documents.find((document) => document.folderType === FOLDER_TYPE_DRAFT_POLICY)) {
			dispatch(
				updateLeftNav({
					reloadLeftNav: { policies: Date.now() },
				}),
			);
		}
	};

	const afterPermanentlyDelete = (documents) => {
		telemetryAddEvent(`${telemetryPage} - Library - Permanently deleted document`, { area: "documents" });

		removeDocuments(documents);
	};

	const getExpandedPolicyParentGuids = (guids) => {
		request
			.get(`${API_HOST}/api/policies/parentids?ids=${guids}`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					setExpandedPolicies(res.body);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					getExpandedPolicyParentGuids(guids);
				});
			});
	};

	const expandPolicies = (policies) => {
		if (expandedPolicies?.length > 0 && policies?.length > 0) {
			// Expand the policies that are parents of the expanded policies
			policies
				.filter(
					(policy) =>
						policy.folder &&
						!policy.expanded &&
						!policy.expandChecked &&
						(expandedPolicies.includes(policy.guid) || expandedPolicies.includes(policy.publishedGuid)),
				)
				.forEach((policy) => {
					policy.expanded = true;

					// Trigger re-render
					setPolicies((prev) => {
						// Load children if needed
						if (!prev[policy.guid]) {
							loadDocuments(policy.guid, false, policy);
						}

						return { ...prev };
					});
				});

			// Mark policy as checked
			policies
				.filter((policy) => !policy.expandChecked)
				.forEach((policy) => {
					policy.expandChecked = true;
				});
		}
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setPolicies((prev) => {
			if (prev) {
				Object.keys(prev).forEach((key) => {
					if (Array.isArray(prev[key])) {
						prev[key].sort(sortMethod);
					}
				});

				return { ...prev };
			} else {
				return prev;
			}
		});
	}, [order]);

	useEffect(() => {
		if (filter) {
			let filteredData = {};
			if (filter.type === 1) {
				for (const [key, value] of Object.entries(policies)) {
					if (Array.isArray(value)) {
						filteredData[key] = value.filter((policy) => policy.publishedGuid !== null);
					}
				}
				setFilteredPolicies(filteredData);
				setFilterOn(true);
			} else if (filter.type === 2) {
				for (const [key, value] of Object.entries(policies)) {
					if (Array.isArray(value)) {
						filteredData[key] = value.filter((policy) => policy.publishedGuid == null);
					}
				}
				setFilteredPolicies(filteredData);
				setFilterOn(true);
			} else {
				setFilteredPolicies(null);
				setFilterOn(false);
			}
			closeFilter();
		}
	}, [filter, policies]);

	useEffect(() => {
		if (policies && policies.root?.length > 0 && isRecycleBin) {
			let rowSelected = policies.root.some((policy) => policy.selected);
			if (!rowSelected) {
				rowSelected =
					childFolderGuids.length > 0 && childFolderGuids.some((childGuid) => policies[childGuid].some((policy) => policy.selected));
			}
			rowSelected ? setEnableRecycleTopBar(true) : setEnableRecycleTopBar(false);
		}
	}, [policies]);

	useEffect(() => {
		if (type === FOLDER_TYPE_DRAFT_POLICY && path && path.length > 0) {
			let code = path[path.length - 1].code !== null ? path[path.length - 1].code : "";
			let title = path[path.length - 1].title;
			setTitle(code + " " + title);
		} else if (type === FOLDER_TYPE_RECYCLE_BIN) {
			setTitle("Recycle Bin");
		}
		dispatch(
			updatePageHeader({
				leftMenuOptions:
					path && widthDownSm
						? path
								.filter((folder) => folder.url)
								.map((folder) => ({
									label: folder.name,
									actionFunction: () => navigate(folder.url),
								}))
						: [],
			}),
		);
	}, [path]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));

		setPolicies({ folder: { id, type } });

		loadPermissions();
		loadDocuments(id, true);
	}, [id, type]);

	useEffect(() => {
		setTitle(""); // Show a blank title until the path is loaded
		loadPath();
	}, [id]);

	useEffect(() => {
		if (id && path) {
			if (path.length > 1) {
				setLandPage(false);
			} else {
				setLandPage(true);
			}
			setFilterOn(false);
			setFilter({ type: 0 });
			setFilteredPolicies(null);
		}
	}, [id, path]);

	useEffect(() => {
		// Scroll a row into view and then focus on the link
		if (focusGuid) {
			const link = document.getElementById(`link-${focusGuid}`);
			if (link) {
				const scrollParent = getScrollParent(link);
				if (scrollParent) {
					const yOffset = -60; // Give space for the table header (40px) plus some extra
					const y = link.getBoundingClientRect().top - scrollParent.getBoundingClientRect().top + yOffset;
					scrollParent.scrollTo({ top: y, behavior: "smooth" });
				}
				link.focus({ preventScroll: true });
			}
		}
	}, [focusGuid]);

	useEffect(() => {
		if (meetingGroups.length > 0) {
			setShowAddToMeetingDialog(true);
		}
	}, [meetingGroups]);

	useEffect(() => {
		// Expand the policies that are parents of the expanded policies
		if (expandedPolicies && policies) {
			Object.keys(policies).forEach((key) => {
				if (Array.isArray(policies[key])) {
					expandPolicies(policies[key]);
				}
			});
		}
	}, [expandedPolicies, policies]);

	useEffect(() => {
		// Get the expanded policy parent guids from the query string
		const queryStringValues = queryString.parse(location.search) || {};
		if (queryStringValues.expanded) {
			getExpandedPolicyParentGuids(queryStringValues.expanded);
		}
	}, []);

	const getChildDocuments = useCallback(
		(list, childDocuments, depth = 0) => {
			let updatedList = list;

			if (childDocuments) {
				for (let index = 0; index < childDocuments.length; index++) {
					const childDocument = childDocuments[index];

					childDocument.depth = depth;

					updatedList.push(childDocument);

					if (childDocument.folder && childDocument.expanded) {
						updatedList = getChildDocuments(updatedList, policies[childDocument.guid], depth + 1);
					}
				}
			} else {
				updatedList.push({ loading: true });
			}

			return updatedList;
		},
		[policies],
	);

	const getTypeOptions = () => {
		const typeOptions = [
			{ text: "All", value: 0, key: "all" },
			{ text: "Published", value: 1 },
			{ text: "Unpublished", value: 2 },
		];
		return typeOptions;
	};

	return !navigateNewPolicy ? (
		<ComponentContainer padding="0">
			<PolicyTopBar
				id={id}
				type={type}
				permissions={permissions}
				uploadClick={uploadClick}
				addFolderClick={addFolderClick}
				permanentlyDeleteClick={permanentlyDeleteClick}
				restoreClick={restoreClick}
				handleSearch={handleSearch}
				filterClick={filterClick}
				filtered={filter}
				newPolicyClick={toggleNewPolicyModal}
				landingPage={landPage}
				onClearSearchClick={onClearSearchClick}
				isRecycleBin={isRecycleBin}
				enableRecycleTopBar={enableRecycleTopBar}
				disableFilter={!(policies?.root?.length > 0)}
			></PolicyTopBar>
			{policies && !widthDownSm && <Breadcrumbs className={classes.breadcrumbs} showMenu items={breadPath} separator="/"></Breadcrumbs>}
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={220} fullHeight>
				<FilterPanel
					filters={filter}
					onFilterChange={filterPanelChange}
					isFiltered={false} //for clear filter option//
					closeFilter={closeFilter}
				>
					<RadioButton
						row
						options={getTypeOptions()}
						getId={(option) => `search-type-${option.key || option.value}`}
						getKey={(option) => option.key || option.value}
						getValue={(option) => option.value}
						getDataCy={(option) => `search-type-${option.key || option.value}`}
						getLabel={(option) => option.text}
						handleChange={(value) => updateFilter({ target: { value } }, "type", false, true)}
						objectToUpdate={filter}
						field="type"
					/>
					{/* <PolicyFilter filter={filter} publishedType={publishedType} updateFilter={updateFilter} /> */}
				</FilterPanel>
			</RightPanelContainer>

			<PolicyList
				label={path?.[0]?.title || ""}
				type={type}
				order={order}
				permissions={permissions}
				policies={filterOn ? filteredPolicies : policies}
				handleEvent={handlePolicyEvent}
				handleSort={handleSort}
				handleCheck={handleCheck}
				telemetryPage={telemetryPage}
				landingPage={landPage}
				filterOn={filterOn && filter.type}
			></PolicyList>
			<input
				className={classes.fileInput}
				id="upload-input"
				type="file"
				ref={input}
				onChange={uploadInputChange}
				accept={getAllowedAcceptForPolicy()}
				tabIndex={-1}
				aria-label={t("app:buttons.upload")}
				aria-hidden="true"
			/>
			{dialogs.upload && (
				<UploadDialog
					id={id}
					type={type}
					files={dialogs.upload.files}
					invalidFiles={dialogs.upload.invalidFiles}
					onClose={closeUpload}
					afterUploadFile={afterUploadFile}
					showSignIn={showSignIn}
					telemetryPage={telemetryPage}
				/>
			)}
			{dialogs.failedUploads && (
				<FileUploadFailureDialog
					show={dialogs.failedUploads && dialogs.failedUploads.length > 0}
					failedFileUploads={dialogs.failedUploads}
					onClose={closeDialogs}
					isPolicy={true}
				/>
			)}
			{dialogs.addFolder && (
				<AddFolderDialog
					id={id}
					type={type}
					onClose={closeDialogs}
					afterAddFolder={afterAddFolder}
					showSignIn={showSignIn}
					isAddPopup={true}
				/>
			)}
			{dialogs.rename && (
				<AddFolderDialog
					document={dialogs.rename}
					onClose={closeDialogs}
					afterRename={afterRename}
					showSignIn={showSignIn}
					isAddPopup={false}
					isEllipsed
				/>
			)}
			{dialogs.publishSectionName && <PublishSectionNameDialog section={dialogs.publishSectionName} onClose={closeDialogs} />}
			{dialogs.delete && (
				<PolicyDeleteDialog
					document={dialogs.delete}
					onClose={closeDialogs}
					afterDelete={afterDelete}
					undoDelete={undoDelete}
					parentGuid={dialogs?.delete?.guid}
					isSection
				/>
			)}
			{dialogs.restore && (
				<PolicyRestoreDialog
					policies={Array.isArray(dialogs.restore) ? dialogs.restore : getSelected().sort(sortDefault)}
					onClose={closeDialogs}
					afterRestore={afterRestore}
					showSignIn={showSignIn}
				/>
			)}
			{dialogs.permanentlyDelete && (
				<PolicyPermanentlyDeleteDialog
					policies={Array.isArray(dialogs.permanentlyDelete) ? dialogs.permanentlyDelete : getSelected().sort(sortDefault)}
					onClose={closeDialogs}
					afterPermanentlyDelete={afterPermanentlyDelete}
					showSignIn={showSignIn}
				/>
			)}
			{dialogs.copyMove && <PolicyMoveCopyDialog document={dialogs.copyMove} onClose={closeDialogs} afterCopyMove={afterCopyMove} />}
			{showAddToMeetingDialog && (
				<AddToAgendaDialog
					show
					meetingDate={today}
					meetingStartTime={today}
					meetingGroups={meetingGroups}
					isPolicy
					policyGuid={policyGuid}
					finishPolicyUrl={`/policy/policybook/${id}`}
					onClose={() => setShowAddToMeetingDialog(false)}
				/>
			)}
			{isNewPolicyModalOpen && (
				<AddPolicyDialog show onClose={toggleNewPolicyModal} onSubmit={newPolicyClick} setPolicyDetails={setPolicyDetails} />
			)}
		</ComponentContainer>
	) : (
		<>
			<div className={classes.overlayLoader} />
			<CircularProgressIndicator className={classes.loader} />
		</>
	);
};

export default PolicyModule;
