import React, { useState, useEffect, useRef } from "react";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DocumentTreeItem from "./DocumentTreeItem";

const useStyles = makeStyles(() => ({
	list: {
		listStyleType: "none",
		margin: "0",
		padding: "0",
	},
}));

const DocumentTree = (props) => {
	const { documents, parent, columns, label, type, inPortal, depth, handleEvent, anchor, setAnchor, telemetryPage } = props;
	const classes = useStyles();

	const documentList = documents?.[parent?.guid || "root"];

	const pageSize = 50;
	const [visibleDocuments, setVisibleDocuments] = useState(pageSize);
	const [loading, setLoading] = useState(false);
	const observerRef = useRef(null);

	const childRendered = (childElement) => {
		// Focus on the first child when the parent is expanded
		if (parent?.expanded && parent?.focusChild && childElement) {
			childElement.focus({ focusVisible: parent.focusKeyboard });
			parent.focusChild = false;
			parent.focusKeyboard = false;
		}
	};

	const handleLoadMore = () => {
		if (loading) return;
		setLoading(true);

		setTimeout(() => {
			setVisibleDocuments((prev) => prev + pageSize);
			setLoading(false);
		}, 200);
	};

	useEffect(() => {
		if (!documentList || visibleDocuments >= documentList.length) return;

		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					handleLoadMore();
				}
			},
			{ threshold: 1.0 },
		);

		if (observerRef.current) {
			observer.observe(observerRef.current);
		}

		return () => {
			if (observerRef.current) {
				observer.unobserve(observerRef.current);
			}
		};
	}, [documentList, visibleDocuments, loading]);

	return documentList ? (
		<>
			<ul className={classes.list} role={!parent ? "tree" : "group"} aria-label={label} data-cy="document-list">
				{documentList.slice(0, visibleDocuments).map((document, index) => (
					<DocumentTreeItem
						key={document.guid}
						documents={documents}
						document={document}
						columns={columns}
						type={type}
						inPortal={inPortal}
						depth={depth}
						handleEvent={handleEvent}
						anchor={anchor}
						setAnchor={setAnchor}
						telemetryPage={telemetryPage}
						childRendered={index === 0 ? childRendered : undefined}
					/>
				))}
			</ul>
			<div ref={observerRef} />
			{loading && <CircularProgressIndicator />}
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default DocumentTree;
